import React from "react"
import { Link } from "gatsby"

import {
  Button,
  Column,
  Container,
  DunkText,
  Flex,
  H1,
  SEO,
  Text,
} from "components"

const NotFoundPage = () => (
  <>
    <SEO title="404: Page not found" />
    <Flex
      as="section"
      flexDirection="column"
      justifyContent="center"
      minHeight="100vh"
      pt={[96, 120]}
      bg="background"
    >
      <Container py={[5, 6]}>
        <Column left>
          <H1 as="h1">
            <DunkText lines={["Page ", "not found"]} />
          </H1>
          <Text
            children={`This page was either deleted or never existed. Error code 404. `}
            mt={[3, 4]}
            fontFamily="body"
            fontSize={[4, 5]}
          />
          <Button children={`Go to home`} as={Link} to="/" mt={[3, 4]} />
        </Column>
      </Container>
    </Flex>
  </>
)

export default NotFoundPage
